import { useEffect, useState } from 'react'
import useContactForm from '@/hooks/useContactForm'
import Input from '@/components/common/input'
import Select from '@/components/common/select'
import Button from '@/components/common/button'
import Alert from '@/components/common/alert'
import FormError from '@/components/common/form-error'

const FormContact = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [marketing, setMarketing] = useState(false)

  const {
    success,
    error,
    inProgress,
    categories,
    activeCategory,
    handleCategory,
    handleSubmit
  } = useContactForm()

  const onSubmit = e => {
    handleSubmit(
      {
        firstName,
        lastName,
        email,
        phone,
        orderNumber,
        subject,
        message,
        marketing
      },
      e
    )
  }

  useEffect(() => {
    if (success) {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setOrderNumber('')
      setSubject('')
      setMessage('')
      setMarketing(false)
    }
  }, [success])

  return (
    <form onSubmit={onSubmit}>
      <div className="row row-sm">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <Input
              type="text"
              required={true}
              placeholder="First Name*"
              value={firstName}
              onChange={setFirstName}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <Input
              type="text"
              required={true}
              placeholder="Last Name*"
              value={lastName}
              onChange={setLastName}
            />
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-12 col-lg-6">
          <div className="mb-4">
            <Input
              type="email"
              required={true}
              placeholder="Email Address*"
              value={email}
              onChange={setEmail}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-4">
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={setPhone}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <Input
          type="number"
          placeholder="0123456"
          label="Optional - add your order number"
          value={orderNumber}
          onChange={setOrderNumber}
        />
      </div>
      <p className="mb-1 text-normal">
        By categorising your question we are able to help you faster
      </p>
      <div className="mb-5">
        <Select
          items={categories}
          selected={activeCategory}
          onChange={handleCategory}
        />
      </div>
      <div className="mb-3">
        <Input
          type="text"
          required={true}
          placeholder="Subject*"
          label="What is your question?"
          value={subject}
          onChange={setSubject}
        />
      </div>
      <div className="mb-4">
        <Input
          type="textarea"
          required={true}
          placeholder="Write your question in detail*"
          value={message}
          onChange={setMessage}
        />
      </div>
      <div className="mb-5">
        <Input
          type="checkbox"
          required={true}
          label="By ticking this box you agree to be contacted via email. Please see our Terms & Conditions for more information."
          value={marketing}
          onChange={setMarketing}
        />
      </div>
      {error && (
        <div className="row row-sm">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <Alert type="error">
                We&apos;re sorry, an error occurred while trying to send your
                message. Please re-check the information you entered, or try
                again later if this error persists.
              </Alert>
            </div>
          </div>
        </div>
      )}
      <div className="row row-sm">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <Button
              form={true}
              type="submit"
              disabled={inProgress}
              className={'w-100'}
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
      <p className="text-normal color-mid-grey p-sm">
        Our customer support team is available during work days from 9am - 5pm
        UK time zone.
      </p>
      {error && <FormError error={error} />}
    </form>
  )
}

export default FormContact
