import cx from 'clsx'
import Picture from '@/components/common/picture'
import Button from '@/components/common/button'
import styles from './newsletter.module.scss'

const Newsletter = ({ title, image, hideButton = false }) => {
  return (
    <div className={styles.root}>
      <div
        className={cx(
          styles.newsContainer,
          'container py-7 position-relative z-index-1'
        )}
      >
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mx-n2">
          {title && (
            <div className="px-2 text-center text-sm-left mb-2 mb-sm-0">
              <h3 className={cx('mb-0', styles.title)}>
                <span>{title}</span>
              </h3>
            </div>
          )}
          {!hideButton && (
            <div className="px-2">
              <Button href="/account/register" lightHover thin>
                Join Now
              </Button>
            </div>
          )}
        </div>
      </div>
      {image && <Picture image={image} />}
    </div>
  )
}

export default Newsletter
