import Button from '@/components/common/button'
import BannerOverlay from '@/components/common/banner-overlay'
import styles from './banner-heading.module.scss'

const BannerHeading = ({ content, button }) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-9 col-lg-7 col-xl-6 d-flex text-center flex-column justify-content-center align-items-center">
              {content && (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              )}
              {button?.title && (
                <div className="mt-3">
                  <Button href={button.url}>{button.title}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BannerOverlay noImage="true" />
    </div>
  )
}

export default BannerHeading
