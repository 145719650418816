import { reset } from '@/lib/store/slice/auth'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import useStatus from '../storefront/useStatus'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const useResetPassword = () => {
  const router = useRouter()
  const [inProgress, error] = useStatus('auth/reset')
  const dispatch = useDispatch()

  const resetUrl = publicRuntimeConfig.publicStore + router.asPath

  const update = password => dispatch(reset({ resetUrl, password }))

  return [error, inProgress, update]
}

export default useResetPassword
