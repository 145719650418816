import { updatePassword } from '@/lib/store/slice/customer'
import { useDispatch } from 'react-redux'
import useStatus from '../storefront/useStatus'

const useSetNewPassword = () => {
  const [inProgress, error] = useStatus('customer/password/update')
  const dispatch = useDispatch()

  const update = password => dispatch(updatePassword({ password }))
  return [error, inProgress, update]
}

export default useSetNewPassword
