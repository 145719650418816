import cx from 'clsx'
import Icon from '@/components/common/icon'
import { useEffect, useRef, useState } from 'react'
import styles from './select.module'

const Select = props => {
  // Set variables
  const [open, setOpen] = useState(null)
  const [selected, setSelected] = useState('')
  const inputRef = useRef()

  // Initial selected value
  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected)
    } else if (props.items) {
      setSelected(props.items[0].value)
    }
  }, [props.selected, props.items])

  // Open/Close dropdown
  const toggle = () => {
    !open && inputRef.current.focus()
    setOpen(!open)
  }

  // When option clicked
  const optionClicked = item => () => {
    setSelected(item.value)
    setOpen(false)
    props.onChange && props.onChange(item)
  }

  // On lose focus of input
  const blur = e => {
    setOpen(false)
    setTimeout(() => {}, 400)
  }

  return (
    <div
      className={cx(styles.root, props.className, {
        [styles.borderless]: props.borderless
      })}
    >
      <div className={styles.selected} onClick={toggle}>
        {props.prepend && <span className={styles.pre}>{props.prepend}</span>}
        <input readOnly value={selected} ref={inputRef} onBlur={blur} />
        <Icon icon="ChevronDown" />
      </div>

      <div className={cx(styles.options, { [styles.open]: open })}>
        {props.items.map((item, i) => (
          <div
            className={styles.option}
            key={i}
            onMouseDown={optionClicked(item)}
          >
            {item.value}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Select
