import { useState } from 'react'
import Input from '@/components/common/input'
import Button from '@/components/common/button'
import FormError from '@/components/common/form-error'
import useActivate from '@/hooks/customer/useActivate'

const FormActivate = () => {
  const [error, inProgress, update] = useActivate()
  const [localError, setLocalError] = useState()

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const updatePassword = () => {
    setLocalError('')
    if (!password) {
      return setLocalError('Password is empty')
    }
    if (password !== password2) {
      return setLocalError('Passwords do not match')
    }
    update(password)
    setPassword('')
    setPassword2('')
  }

  return (
    <form>
      <div className="row row-sm">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <Input
              type="password"
              required={true}
              placeholder="New Password"
              value={password}
              onChange={setPassword}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <Input
              type="password"
              required={true}
              placeholder="New Password Again"
              value={password2}
              onChange={setPassword2}
              autoComplete="new-password"
            />
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-12 col-lg-6">
          <Button
            className="w-100"
            disabled={inProgress || !password}
            onClick={updatePassword}
          >
            Set New Password
          </Button>
        </div>
      </div>
      {(error || localError) && <FormError error={error || localError} />}
    </form>
  )
}

export default FormActivate
