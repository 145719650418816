import cx from 'clsx'
import FormPassword from '@/components/forms/new-password'
import ResetPassword from '@/components/forms/reset-password'
import FormContact from '@/components/forms/contact'
import FormLogin from '@/components/forms/login'
import FormRegister from '@/components/forms/register'
import FormForgot from '@/components/forms/forgot-password'
import Activate from '@/components/forms/activate'

const FormBlock = props => {
  const justifyContent =
    props?.justify == 'center'
      ? 'center'
      : props?.justify == 'right'
      ? 'end'
      : 'start'

  return (
    <div className="container">
      <div className={cx('row', 'justify-content-' + justifyContent)}>
        <div className="col-12 col-md-10 col-lg-8">
          {props?.content && (
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: props?.content }}
            />
          )}
          {props?.form == 'contact' && <FormContact />}
          {props?.form == 'login' && <FormLogin hasMarketing={true} />}
          {props?.form == 'register' && (
            <FormRegister {...props?.global?.register} />
          )}
          {props?.form == 'forgot-password' && <FormForgot />}
          {props?.form == 'new-password' && <FormPassword />}
          {props?.form == 'reset-password' && <ResetPassword />}
          {props?.form == 'activate' && <Activate />}
        </div>
      </div>
    </div>
  )
}

export default FormBlock
