import cx from 'clsx'
import styles from './alert.module'

const Alert = props => {
  const className = cx(styles.root, props.className, {
    [styles.error]: props.type === 'error',
    [styles.warning]: props.type === 'warning',
    [styles.success]: props.type === 'success',
    [styles.information]: props.type === 'information'
  })

  return <div className={className}>{props.children}</div>
}

export default Alert
