import cx from 'clsx'
import styles from './banner-overlay.module.scss'

export const BannerOverlay = ({ invert = false, noImage = false }) => {
  return (
    <div
      className={cx(styles.root, {
        [styles.invert]: invert,
        [styles.noImage]: noImage
      })}
    >
      <div className="d-flex">
        <span className={cx(styles.corner, styles.cornerTopLeft)}></span>
        <span className={cx(styles.corner, styles.cornerTopRight)}></span>
      </div>
      <div className="d-none d-sm-flex"></div>
      <div className="d-flex">
        <span className={cx(styles.corner, styles.cornerBottomLeft)}></span>
        <span className={cx(styles.corner, styles.cornerBottomRight)}></span>
      </div>
    </div>
  )
}

export default BannerOverlay
