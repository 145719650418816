import { useState } from 'react'
import notify from '@/lib/notify'

const useContactForm = categories => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  categories = (
    categories || [
      'Where is my order?',
      'Order issue',
      'Product question',
      'Refund query',
      'Other'
    ]
  ).map(category => ({ key: category, value: category }))
  const [activeCategory, setActiveCategory] = useState(categories[0].value)

  const handleSubmit = (data, event) => {
    event.preventDefault()

    setSuccess(false)
    setError(false)
    setInProgress(true)

    try {
      fetch(`${process.env.NEXT_PUBLIC_API}/contact-form`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          category: activeCategory
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          setInProgress(false)

          if (res.status !== 201) {
            throw new Error('Invalid code')
          }

          setSuccess(true)

          notify({ text: 'Your message has been successfully sent' })
        })
        .catch(() => {
          setInProgress(false)
          setError(true)

          notify({ text: 'Your message could not be sent due to an error' })
        })
    } catch (error) {
      setInProgress(false)
      setError(true)
    }
  }

  const handleCategory = category => {
    const selectedCategory = categories.find(
      item => item.value === category.value
    )
    if (selectedCategory) {
      setActiveCategory(category.value)
    }
  }

  return {
    success,
    error,
    inProgress,
    categories,
    activeCategory,
    handleCategory,
    handleSubmit
  }
}

export default useContactForm
